import { ActionType } from '../../actions';

export const initialState = {
  isLoading: false
};

export const reducer = (state, action) => {
  switch (action.type) {
    case ActionType.loadingOn:
      return {
        isLoading: true
      };
    case ActionType.loadingOff:
      return {
        isLoading: false
      };

    default:
      return state;
  }
};
