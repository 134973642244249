import Intro from '../../components/Intro/Intro';
import WhatIDoList from '../../components/WhatIDoList/WhatIDoList';
import TestimonialList from '../../components/TestimonialList/TestimonialList';
import Stack from '../../components/Stack/Stack';
import FunFacts from '../../components/FunFacts/FunFacts';

const Main = () => {
  return (
    <>
      <Intro />
      <WhatIDoList />
      <TestimonialList />
      <Stack />
      <FunFacts />
    </>
  );
};

export default Main;
