import { ActionType } from '../../actions';

export const initialState = {
  projects: null
};

export const reducer = (state, action) => {
  switch (action.type) {
    case ActionType.setProjects:
      return {
        projects: action.payload
      };

    default:
      return state;
  }
};
