import SectionHeader from '../SectionHeader/SectionHeader';
import { STACK_IMAGES } from '../../data';

import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Autoplay } from 'swiper';

const Stack = () => {
  return (
    <section className="mb-20">
      <SectionHeader>Stack</SectionHeader>
      <Swiper
        modules={[A11y, Autoplay]}
        autoplay={{ delay: 1 }}
        breakpoints={{
          0: {
            slidesPerView: 4
          },
          599: {
            slidesPerView: 6
          },
          999: {
            slidesPerView: 8
          }
        }}
        speed={5000}
        slidesPerView={8}
        spaceBetween={50}
        loop={true}
        containerModifierClass={'swiper-custom_'}
      >
        {STACK_IMAGES.map((imageName, index) => {
          return (
            <SwiperSlide key={index} className="">
              <img
                src={`/img/stack/${imageName}.svg`}
                alt={imageName}
                title={imageName}
                className="opacity-50 hover:opacity-70 dark:filter-white filter-black h-16"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Stack;
