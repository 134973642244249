import { createContext, useReducer } from 'react';
import { reducer, initialState } from './reducer';
import PropTypes from 'prop-types';

export const LoadingContext = createContext({
  initialState,
  dispatch: () => null
});

export const LoadingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <LoadingContext.Provider value={[state, dispatch]}>{children}</LoadingContext.Provider>;
};

LoadingProvider.propTypes = {
  children: PropTypes.node
};
