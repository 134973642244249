import { ActionType } from '../../actions';

export const initialState = {
  pickedColor: localStorage.getItem('color') || 'rgb(50,123,277)',
  darkMode: JSON.parse(localStorage.getItem('darkMode')) === true,
  colors: [
    'rgb(245,158,11)',
    'rgb(6,182,212)',
    'rgb(20,184,166)',
    'rgb(217,70,239)',
    'rgb(244,63,94)',
    'rgb(50,123,277)'
  ]
};

export const reducer = (state, action) => {
  switch (action.type) {
    case ActionType.changeColor:
      localStorage.setItem('color', action.payload);
      return {
        ...state,
        pickedColor: action.payload
      };
    case ActionType.toggleDarkMode:
      localStorage.setItem('darkMode', JSON.stringify(!state.darkMode));
      return {
        ...state,
        darkMode: !state.darkMode
      };

    default:
      return state;
  }
};
