import React from 'react';
import { createRoot } from 'react-dom/client';
import smoothscroll from 'smoothscroll-polyfill';

import reportWebVitals from './reportWebVitals';
import App from './App';
// import { getData } from './api';

import './index.css';

// getData();

smoothscroll.polyfill();

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
