import bgbubbles from './bg-bubbles.png';
import useBgMove from '../../hooks/useBgMove';
import { ColorContext } from '../../context/Color';
import { useContext } from 'react';

const MainBackground = () => {
  const { x, y } = useBgMove();
  const [state] = useContext(ColorContext);

  const backgroundStyle = {
    backgroundImage: `url(${bgbubbles}), linear-gradient(${state.pickedColor},${state.pickedColor})`,
    backgroundPosition: `calc(50% + ${-x / 150}px) calc(50% + ${-y / 150}px)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  };

  return <div style={backgroundStyle} className="-z-10 w-[102%] h-[102%] fixed top-0 left-0"></div>;
};

export default MainBackground;
