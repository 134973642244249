import { useRef, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import 'react-toastify/dist/ReactToastify.css';

import Button from '../../components/Button/Button';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import Map from '../../components/Map/Map';
import useFetch from '../../hooks/useFetch';
import { ColorContext } from '../../context/Color';
import { shakeElement } from '../../utils/shakeElement';

const Contact = () => {
  const [{ darkMode }] = useContext(ColorContext);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, reset, formState, setValue } = useForm({ mode: 'onChange' });
  const buttonRef = useRef(null);
  const { post } = useFetch();

  const onSubmit = (data) => {
    console.log(data);
    setIsLoading(true);
    post('/form', data)
      .then((res) => {
        if (res.status === 200) {
          toast.success(`${res.data.fullName}, your message has been sent! Thank you!`);
          reset();
        }
      })
      .catch((err) => {
        console.log(err.message);
        toast.error('Something went wrong! Please try again later.');
        shakeElement(buttonRef.current);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onVerifyCaptcha = (token) => {
    setValue('captchaToken', token);
  };

  return (
    <>
      <SectionHeader page>Contact</SectionHeader>
      <Map />
      <section className="mt-16">
        <SectionHeader>How Can I Help You?</SectionHeader>
        <form className="flex flex-wrap" onSubmit={handleSubmit(onSubmit)}>
          <div className="sm:w-1/2 w-full">
            <input
              {...register('fullName', { required: true })}
              autoComplete="name"
              type="text"
              required
              className="sm:w-11/12 w-full mb-6 block rounded-md border-gray-300 bg-transparent shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Full Name"
            />
            <label
              before={formState.errors.email?.message}
              className={
                formState.errors.email?.message &&
                'block relative before:absolute before:-top-5 before:left-0 before:w-60 before:h-10 before:content-[attr(before)] before:text-xs before:text-red-400'
              }
            >
              <input
                {...register('email', {
                  required: true,
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email!'
                  }
                })}
                autoComplete="email"
                type="email"
                required
                className="sm:w-11/12 w-full mb-6 block rounded-md border-gray-300 shadow-sm bg-transparent
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="Email Adress"
              />
            </label>
            <input
              {...register('subject')}
              type="text"
              className="sm:w-11/12 w-full block rounded-md border-gray-300 shadow-sm bg-transparent
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Subject"
            />
          </div>
          <textarea
            {...register('message', { required: true })}
            placeholder="Your message"
            minLength={10}
            required
            className="block sm:w-1/2 w-full mt-6 sm:mt-0 rounded-md border-gray-300 shadow-sm bg-transparent
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
          ></textarea>
          <div
            before={formState.errors.captchaToken?.message}
            className={`w-full mt-6 ${
              formState.errors.captchaToken?.message
                ? 'block relative before:absolute before:-top-5 before:left-0 before:w-60 before:h-10 before:content-[attr(before)] before:text-xs before:text-red-400'
                : ''
            }
            `}
          >
            <HCaptcha
              {...register('captchaToken', {
                required: 'Please make sure you are not a robot 🤖'
              })}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              theme={darkMode ? 'dark' : 'light'}
              onVerify={onVerifyCaptcha}
            />
          </div>
          <Button isLoading={isLoading} submit colorfull classes="mt-6" buttonRef={buttonRef}>
            {isLoading ? 'Sending...' : 'Send message'}
          </Button>
        </form>
      </section>
    </>
  );
};

export default Contact;
