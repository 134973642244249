import { MapContainer, TileLayer, Marker } from 'react-leaflet';

const CENTER = [39.992, -75.176];

const Map = () => {
  return (
    <MapContainer center={CENTER} zoom={10} scrollWheelZoom={false} className="h-52 z-40">
      <TileLayer
        zIndex={1}
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={CENTER} />
    </MapContainer>
  );
};

export default Map;
