import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppRoute } from '../../utils/const';

const Project = ({ imgSrc, tag, title, id }) => {
  const [isVisible, setIsVisible] = useState(false);

  const hoverElement = () => {
    setIsVisible(true);
  };

  const unhoverElement = () => {
    setIsVisible(false);
  };

  return (
    <li className="relative overflow-hidden rounded-xl border-[1px] border-zinc-300 dark:border-zinc-600 shadow-xl">
      <Link
        to={`${AppRoute.PORTFOLIO}/${id}`}
        onMouseEnter={hoverElement}
        onMouseLeave={unhoverElement}
      >
        <h2
          className={`bg-zinc-200 absolute top-2 left-2 z-10 rounded-md py-1 px-3 dark:text-zinc-800 transition-opacity`}
        >
          {title}
        </h2>
        <img
          className="sm:hover:scale-105 transition-all sm:hover:opacity-80"
          src={imgSrc}
          alt={title + 'project'}
        />
        <span
          className={`${
            isVisible ? 'md:opacity-100' : 'md:opacity-0'
          } border-2 absolute bottom-2 right-2 bg-[rgba(255,255,255,0.7)] py-1 px-2 text-xs rounded-xl dark:text-zinc-800 transition-opacity`}
        >
          {tag.toUpperCase()}
        </span>
      </Link>
    </li>
  );
};

Project.propTypes = {
  id: PropTypes.number.isRequired,
  imgSrc: PropTypes.string.isRequired,
  stack: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired
};

export default Project;
