import { Link } from 'react-router-dom';
import { AppRoute } from '../../utils/const';

const NotFound = () => {
  return (
    <div className="text-center">
      <h1 className="text-9xl font-bold">404</h1>
      <h2 className="mb-10 text-xl">There is nothing here 😬</h2>
      <Link to={AppRoute.ROOT}>Go to main page</Link>
    </div>
  );
};

export default NotFound;
