import Testimonial from '../Testimonial/Testimonial';
import SectionHeader from '../SectionHeader/SectionHeader';
import { TESTIMONIALS } from '../../data';

import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Autoplay } from 'swiper';

const TestimonialList = () => {
  return (
    <section className="mb-20">
      <SectionHeader>Testimonials</SectionHeader>
      <div className="sm:flex justify-between flex-wrap block">
        <Swiper
          modules={[A11y, Autoplay]}
          autoplay
          slidesPerView={2}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetweenSlides: 50
            },
            999: {
              slidesPerView: 2,
              spaceBetweenSlides: 50
            }
          }}
          loop={true}
        >
          {TESTIMONIALS.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Testimonial {...item} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default TestimonialList;
