import { useNavigate } from 'react-router-dom';
import uesBgMove from '../../hooks/useBgMove';
import { AppRoute } from '../../utils/const';
import Button from '../Button/Button';

const Intro = () => {
  const { x, y } = uesBgMove();
  let navigate = useNavigate();

  const backgroundStyle = {
    background: 'url(/img/me.jpg) 50% 50% / 105% no-repeat',
    backgroundPosition: `calc(50% + ${x / 150}px) calc(50% + ${y / 150}px)`
  };
  return (
    <section className="intro lg:flex mb-20">
      <div className="photo sm:w-4/5 md:w-3/5 lg:w-2/5 mx-auto flex">
        <div className="w-full self-center overflow-hidden shadow-xl bg-[rgb(222,216,204)] dark:shadow-black rounded-full border-[16px] border-white dark:border-zinc-800">
          <div style={backgroundStyle} className="w-full pb-[100%]"></div>
        </div>
      </div>
      <div className="info lg:w-3/5 lg:pl-20">
        <h4 className="pt-10 text-zinc-500 dark:text-zinc-50 font-extralight">Web-developer</h4>
        <h1 className="py-3 text-6xl dark:text-zinc-50 font-thin">
          <strong>Max</strong> Baravy
        </h1>
        <p className="pb-10 pt-5 text-zinc-700 dark:text-zinc-200">
          Greetings! My name is Max, and I am passionate about creating responsive websites that are
          not only aesthetically pleasing, but also lightning-fast and user-friendly, all while
          adhering to best practices. My expertise lies in front-end development, where I specialize
          in utilizing HTML, CSS, JS, and ReactJS to construct web applications, custom plugins, and
          innovative features. I pride myself on delivering intuitive and engaging user experiences,
          and strive to stay up-to-date on the latest technologies and industry trends.
        </p>
        <Button
          onClick={() => {
            navigate(AppRoute.PORTFOLIO);
          }}
          colorfull
          classes="mr-5"
        >
          PORTFOLIO
        </Button>
        <Button
          onClick={() => {
            navigate(AppRoute.CONTACT);
            window.scrollTo({ top: 1000 });
          }}
        >
          Contact
        </Button>
      </div>
    </section>
  );
};

export default Intro;
