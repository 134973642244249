import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Header from './components/Header/Header';
import MainBackground from './components/MainBackground/MainBackground';
import Footer from './components/Footer/Footer';
import ColorTheme from './components/ColorTheme/ColorTheme';
import Preloader from './components/Preloader/Preloader';

import Main from './pages/Main/Main';
import Contact from './pages/Contact/Contact';
import Blog from './pages/Blog/Blog';
import ProjectPage from './pages/ProjectPage/ProjectPage';
import Portfolio from './pages/Portfolio/Portfolio';
import Resume from './pages/Resume/Resume';
import NotFound from './pages/NotFound/NotFound';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';

import { ColorProvider } from './context/Color';
import { LoadingProvider } from './context/Loading';
import { ProjectsProvider } from './context/Projects';
import { AppRoute } from './utils/const';

function App() {
  return (
    <>
      <HelmetProvider>
        <ColorProvider>
          <LoadingProvider>
            <ProjectsProvider>
              <ToastContainer position="top-center" draggable />
              <ColorTheme />
              <Preloader />
              <MainBackground />
              <ScrollToTop />
              <div className="max-w-7xl xl:my-10 my-0 mx-auto bg-white dark:bg-zinc-800 rounded-none pt-10 shadow-sm xl:rounded-3xl">
                <BrowserRouter>
                  <Header />
                  <main className="p-10 lg:p-20 dark:text-zinc-100">
                    <Routes>
                      <Route path={AppRoute.ROOT} element={<Main />} />
                      <Route path={AppRoute.CONTACT} element={<Contact />} />
                      <Route path={AppRoute.RESUME} element={<Resume />} />
                      <Route path={AppRoute.PORTFOLIO} element={<Portfolio />}>
                        <Route path=":id" element={<ProjectPage />} />
                      </Route>
                      <Route path={AppRoute.BLOG} element={<Blog />} />
                      <Route path={AppRoute.NOT_FOUND} element={<NotFound />} />
                      <Route path="*" element={<Navigate replace to={AppRoute.NOT_FOUND} />} />
                    </Routes>
                  </main>
                  <Footer />
                </BrowserRouter>
              </div>
            </ProjectsProvider>
          </LoadingProvider>
        </ColorProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
