import Menu from '../Menu/Menu';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { capitalize } from '../../utils/capitalize';
import { AppRoute } from '../../utils/const';

const Header = () => {
  let location = useLocation();
  const title =
    location.pathname === AppRoute.ROOT
      ? 'Max Baravy - web developer'
      : capitalize(location.pathname) + ' - Max Baravy - web developer';

  return (
    <header className="px-10">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <div className="header-content flex justify-between">
        <div className="text-logo flex items-center">
          <Link to={AppRoute.ROOT} tabIndex={0}>
            <img src="/img/m.svg" className="h-16 dark:filter-white" alt="logo" />
          </Link>
          <span>
            <Link className="text-xl font-thin dark:text-zinc-50" to={AppRoute.ROOT} tabIndex={0}>
              <strong>Max</strong> Baravy
            </Link>
          </span>
        </div>
        <Menu />
      </div>
    </header>
  );
};

export default Header;
