import PropTypes from 'prop-types';
import ImageViewer from 'react-simple-image-viewer';

const Viewer = ({ currentImage, images, closeImageViewer }) => {
  return (
    <ImageViewer
      src={images}
      currentIndex={currentImage}
      onClose={closeImageViewer}
      disableScroll
      backgroundStyle={{
        backgroundColor: 'rgba(0,0,0,0.8)'
      }}
      closeOnClickOutside={true}
    />
  );
};

Viewer.propTypes = {
  currentImage: PropTypes.number,
  images: PropTypes.arrayOf(PropTypes.string),
  closeImageViewer: PropTypes.func
};

export default Viewer;
