import { useState } from 'react';
import MenuItem from '../MenuItem/MenuItem';

const MENU_ITEMS = [
  { portfolio: 'Portfolio' },
  { resume: 'Resume' },
  { blog: 'Blog' },
  { contact: 'Contact' }
];

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuItemClick = () => {
    setIsOpen(false);
  };

  return (
    <div className="flex items-center z-50">
      <div
        className="menu-button w-6 h-6 cursor-pointer md:hidden"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <svg className="dark:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
        </svg>
      </div>
      <div
        className={`${isOpen ? 'fixed' : 'hidden'} z-50 w-6 h-6 cursor-pointer md:hidden`}
        onClick={() => {
          setIsOpen(false);
        }}
      >
        <svg className="dark:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
          <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
        </svg>
      </div>
      <ul
        className={`menu ${
          isOpen ? 'flex' : 'hidden'
        } place-items-center justify-center flex-col fixed z-40 w-screen h-screen top-0 left-0 bg-white dark:bg-zinc-800 md:static md:flex md:w-auto md:h-auto md:flex-row`}
      >
        {MENU_ITEMS.map((item) => {
          return <MenuItem onClick={handleMenuItemClick} item={item} key={Object.keys(item)} />;
        })}
      </ul>
    </div>
  );
};

export default Menu;
