export const STACK_IMAGES = [
  'react',
  'webpack',
  'js',
  'html5',
  'css3',
  'sass',
  'gulp',
  'wordpress',
  'ts',
  'jest',
  'tailwind',
  'bootstrap',
  'next-js'
];

export const FILTERS = ['all', 'react', 'vue', 'js', 'html'];

export const TESTIMONIALS = [
  {
    personName: 'Pavel',
    companyName: 'Blogger',
    avatar: './img/avatars/1.jpg',
    comment:
      'Happy with the website! I recommend Max as good specialist in the field of web development.'
  },
  {
    personName: 'John Berk',
    companyName: '',
    avatar: '',
    comment:
      'Max has made us a practical and beautiful website that works and brings new customers. The quality work has been done quickly, in line with our wishes and plans.'
  },
  {
    personName: 'Alina Baravy',
    companyName: 'IronBrand',
    avatar: './img/avatars/3.jpg',
    comment:
      "Very pleased with the level of service from Max. Total experience couldn't have been any better. Highly recommend!"
  },
  {
    personName: 'Sarah K.',
    companyName: '',
    avatar: '',
    comment:
      "Max's technical knowledge and attention to detail are second to none. He created a beautiful and responsive website for my business that has increased my online presence and attracted more customers. His ability to understand my vision and bring it to life was truly impressive. I highly recommend Max to anyone looking for a skilled and reliable web developer."
  },
  {
    personName: 'John D.',
    companyName: '',
    avatar: '',
    comment:
      "Working with Max was a pleasure. He is a skilled front-end developer who is always up-to-date with the latest industry trends and best practices. He created a stunning website for our company that not only looks great, but is also fast and user-friendly. He was always available to answer our questions and made the development process seamless. We couldn't be happier with the end result and would recommend Max to anyone looking for a talented web developer."
  }
];

export const EDUCATION = [
  {
    year: '2020',
    title: 'HTML Academy',
    occupation: 'React Frontend Developer',
    description:
      'I have completed an online bootcamp where I gained practical experience in building interactive and responsive user interfaces using React, JavaScript, HTML, and CSS. Through the bootcamp, I have developed skills in working with state management tools such as Redux, styled-components, and react-router-dom. I am proficient in using version control tools like Git and GitHub, and I have experience working in agile development methodologies.'
  },
  {
    year: '2013',
    title: 'The Belarussian State University',
    occupation: 'Civil Engineering Bachelor Degree',
    description:
      'As a civil engineering graduate, I have a strong foundation in the principles of civil engineering, including design, construction, and maintenance of infrastructure projects. During my studies, I developed skills in project management, structural analysis, and technical report writing. I am proficient in using various software applications such as AutoCAD, MATLAB, and STAAD Pro. Additionally, I have experience working in teams, collaborating with contractors, and communicating with clients.'
  }
];

export const EXPERIENCE = [
  {
    year: '2022-present',
    title: 'First Quality RS, King of Prussia, PA',
    occupation: 'Engineering Technician',
    description:
      'Strong background in quality control, process improvement, and technical problem-solving, which I utilize to ensure the highest level of quality and efficiency in the production process. I work collaboratively with cross-functional teams including production, quality assurance, and engineering to ensure successful product development and launch.'
  },
  {
    year: '2016-2022',
    title: 'First Quality RS, King of Prussia, PA',
    occupation: 'Maintenance Technician',
    description:
      'Maintained and repaired production machinery, including conveyors, palletizers, case packers, and baggers. Proficient in troubleshooting mechanical and electrical issues. Successfully completed additional training and certification programs to expand technical knowledge and skills. Promoted to Engineering Technician in recognition of outstanding performance, contributions, and potential for future growth within the organization.'
  },
  {
    year: '2015-2017',
    title: 'Raduga Tech LLC, Remote',
    occupation: 'Web developer',
    description:
      'Developed and maintained responsive websites using JavaScript, HTML, and CSS, ensuring cross-browser compatibility and accessibility.'
  },
  {
    year: '2013-2015',
    title: 'GDSK, Belarus',
    occupation: 'Site Manager/Associate Engineer',
    description:
      'Managed 5 complex projects in construction, ensuring adherence to code and SOPs while resolving deviation events. Led project teams of 30-50 personnel, developing and implementing production plans, and controlling material distribution to laborers'
  }
];

export const CERTIFICATES = [
  {
    date: 'May 2019',
    title: 'Professional JavaScript level 1',
    company: 'HTML Academy',
    image: './img/stack/js.svg',
    imageViewer: './img/certificates/1.jpg'
  },
  {
    date: 'May 2020',
    title: 'Professional JavaScript level 2',
    company: 'HTML Academy',
    image: './img/stack/js.svg',
    imageViewer: './img/certificates/2.jpg'
  },
  {
    date: 'August 2020',
    title: 'React. Development of complex applications',
    company: 'HTML Academy',
    image: './img/stack/react.svg',
    imageViewer: './img/certificates/3.jpg'
  }
];
