import { createContext, useReducer } from 'react';
import { reducer, initialState } from './reducer';
import PropTypes from 'prop-types';

export const ColorContext = createContext({
  initialState,
  dispatch: () => null
});

export const ColorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <ColorContext.Provider value={[state, dispatch]}>{children}</ColorContext.Provider>;
};

ColorProvider.propTypes = {
  children: PropTypes.node
};
