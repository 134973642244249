import PropTypes from 'prop-types';
import { useContext } from 'react';
import { ActionType } from '../../actions';
import { ProjectsContext } from '../../context/Projects';
import useFetch from '../../hooks/useFetch';

const ProjectFilter = ({ filter, activeFilter, setActiveFilter }) => {
  const [, setProjects] = useContext(ProjectsContext);
  const { get } = useFetch();

  const handleClick = () => {
    const getFilteredProjects = (url) => {
      get(url).then((res) => {
        setProjects({
          type: ActionType.setProjects,
          payload: res.data
        });
      });
    };
    setActiveFilter(filter);
    if (filter === 'all') {
      getFilteredProjects('/projects');
    } else {
      getFilteredProjects(`/projects?filter=${filter}`);
    }
  };

  return (
    <li className={`${activeFilter === filter ? '' : 'opacity-50'} ml-3 hover:opacity-100`}>
      <button onClick={handleClick}>{filter.toUpperCase()}</button>
    </li>
  );
};

ProjectFilter.propTypes = {
  filter: PropTypes.string,
  activeFilter: PropTypes.string,
  setActiveFilter: PropTypes.func
};

export default ProjectFilter;
