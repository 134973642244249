import PropTypes from 'prop-types';
import { useContext } from 'react';
import { ColorContext } from '../../context/Color';

const Button = ({
  children,
  colorfull = false,
  classes = '',
  onClick,
  submit = false,
  buttonRef = null,
  isLoading = false
}) => {
  const [state] = useContext(ColorContext);

  const hoverButton = (e) => {
    e.target.style.backgroundColor = state.pickedColor;
  };

  const unhoverButton = (e) => {
    e.target.style.backgroundColor = '';
  };

  if (colorfull) {
    return (
      <button
        ref={buttonRef}
        type={submit ? 'submit' : 'button'}
        onClick={onClick}
        style={{ borderColor: state.pickedColor }}
        onMouseEnter={hoverButton}
        onMouseLeave={unhoverButton}
        className={`${classes} disabled:bg-zinc-400 disabled:text-white border-2 rounded-3xl dark:text-zinc-100 px-6 py-2 hover:text-white transition-all active:ring-2`}
        disabled={isLoading}
      >
        {children}
        {isLoading && (
          <span className="ml-4">
            <svg
              className="inline animate-spin h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke={state.darkMode ? 'white' : 'black'}
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill={state.darkMode ? 'white' : 'black'}
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </span>
        )}
      </button>
    );
  }

  return (
    <button
      onClick={onClick}
      className={`${classes} border-zinc-400 text-zinc-600 border-2 rounded-3xl dark:text-zinc-50 px-6 py-2 
    hover:bg-zinc-400 hover:text-white active:ring-2 transition-all`}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.string.isRequired,
  colorfull: PropTypes.bool,
  classes: PropTypes.string,
  onClick: PropTypes.func,
  submit: PropTypes.bool,
  buttonRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  isLoading: PropTypes.bool
};

export default Button;
