import PropTypes from 'prop-types';
import { useContext } from 'react';
import { filterColor } from '../../utils/filterColor';
import { ColorContext } from '../../context/Color';

const WhatIDoCard = ({ imgSrc, title, description }) => {
  const [state] = useContext(ColorContext);
  return (
    <li className="col-span-4 md:col-span-2 lg:col-span-1 dark:text-zinc-100 grid grid-cols-12">
      <div className={`col-span-2 lg:col-span-1 w-10 ${filterColor(state.pickedColor)}`}>
        <img src={imgSrc} alt="" />
      </div>
      <div className="col-span-10 lg:col-span-11 lg:ml-10 ml-4">
        <h3 className="text-xl pb-2">{title}</h3>
        <p className="font-light">{description}</p>
      </div>
    </li>
  );
};

WhatIDoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired
};

export default WhatIDoCard;
