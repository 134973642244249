import PropTypes from 'prop-types';

const TimeLineItem = ({ item, pickedColor }) => {
  const { year, title, occupation, description } = item;
  return (
    <li
      className="py-3 relative 
  before:h-full before:w-[1px] before:left-5 before:top-0 before:opacity-50 before:absolute before:bg-zinc-300
  after:w-[calc(100%-1.25rem)] after:h-[1px] after:absolute after:bottom-0 after:left-5 after:opacity-50 after:bg-zinc-300"
    >
      <h4
        style={{ border: `2px solid ${pickedColor}` }}
        className="relative inline-block rounded-2xl px-3 bg-white dark:bg-zinc-800"
      >
        {year}
      </h4>
      <span className="px-3 text-sm text-zinc-400 dark:text-zinc-600">{title}</span>
      <h3 className="font-bold ml-10 py-3">{occupation}</h3>
      <p className="ml-10 text-sm pb-3">{description}</p>
    </li>
  );
};

TimeLineItem.propTypes = {
  item: PropTypes.objectOf({
    year: PropTypes.string,
    title: PropTypes.string,
    occupation: PropTypes.string,
    description: PropTypes.string
  }),
  pickedColor: PropTypes.string
};

export default TimeLineItem;
