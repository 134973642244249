import { useEffect, useState } from 'react';

export default function useBgMove() {
  const [state, setState] = useState({});

  useEffect(() => {
    const handlerMouseMove = (e) => {
      setState({ x: e.clientX, y: e.clientY });
    };
    window.addEventListener('mousemove', handlerMouseMove);
    return () => window.removeEventListener('mousemove', handlerMouseMove);
  }, []);

  return state;
}
