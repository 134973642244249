import { createContext, useReducer } from 'react';
import { reducer, initialState } from './reducer';
import PropTypes from 'prop-types';

export const ProjectsContext = createContext({
  initialState,
  dispatch: () => null
});

export const ProjectsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <ProjectsContext.Provider value={[state, dispatch]}>{children}</ProjectsContext.Provider>;
};

ProjectsProvider.propTypes = {
  children: PropTypes.node
};
