import PropTypes from 'prop-types';
import { useContext } from 'react';
import { filterColor } from '../../utils/filterColor';
import { ColorContext } from '../../context/Color';

const placeholderUrl = './img/avatars/placeholder.png';

const Testimonial = ({ personName, companyName, comment, avatar }) => {
  const [state] = useContext(ColorContext);
  const style = {
    backgroundImage: `url(${avatar !== '' ? avatar : placeholderUrl})`,
    backgroundSize: avatar !== '' ? 'cover' : '60%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  };
  return (
    <>
      <article className="w-full p-10 dark:text-zinc-100 relative">
        <div className="relative border-slate-300 dark:border-zinc-700 border-[1px] rounded-xl px-12 py-6 z-20 bg-zinc-50 dark:bg-zinc-700 shadow-md">
          <div
            style={style}
            className="absolute -top-10 -left-10 w-20 h-20 rounded-full bg-slate-500"
          ></div>
          <p className="font-light italic pb-5">{comment}</p>
          <div className="relative">
            <img
              src="/img/quotes/quote.svg"
              alt="quote left"
              className={`absolute -top-2 -left-6 w-4 h-4 ${filterColor(
                state.pickedColor
              )} opacity-30 dark:opacity-60`}
            />
            <h3 className="font-semibold">{personName}</h3>
          </div>
          <p className="font-thin text-sm">{companyName}</p>
        </div>
        <img
          src="/img/quotes/quote.svg"
          alt="quote right"
          className={`absolute bottom-5 right-5 w-9 h-9 ${filterColor(
            state.pickedColor
          )} opacity-30 dark:opacity-60 rotate-180 z-10`}
        />
      </article>
    </>
  );
};

Testimonial.propTypes = {
  personName: PropTypes.string,
  companyName: PropTypes.string,
  comment: PropTypes.string,
  avatar: PropTypes.string
};

export default Testimonial;
