import { useState } from 'react';

import ProjectFilter from '../ProjectFilter/ProjectFilter';
import { FILTERS } from '../../data';

const ProjectFilterList = () => {
  const [activeFilter, setActiveFilter] = useState(FILTERS[0]);

  return (
    <ul className="mb-10 ml-auto w-60 flex justify-between">
      {FILTERS.map((filter, index) => {
        return (
          <ProjectFilter
            key={index}
            filter={filter}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
          />
        );
      })}
    </ul>
  );
};

export default ProjectFilterList;
