import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';

import Carousel from '../../components/Carousel/Carousel';
import { ColorContext } from '../../context/Color';
import { LoadingContext } from '../../context/Loading';

import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { FacebookIcon, LinkedinIcon, TwitterIcon } from 'react-share';
import { AppRoute } from '../../utils/const';
import { shakeElement } from '../../utils/shakeElement';
import useFetch from '../../hooks/useFetch';
import { toast } from 'react-toastify';
import { ActionType } from '../../actions';

const NUMBER_OF_PROJECTS = 7;

const ProjectPage = () => {
  let { id } = useParams();
  const idNumber = +id;

  const refNode = useRef(null);
  let navigate = useNavigate();
  const [project, setProject] = useState();
  const [{ pickedColor }] = useContext(ColorContext);
  const [, setIsLoading] = useContext(LoadingContext);
  const { get } = useFetch();

  const handleCloseClick = () => {
    refNode.current.style.left = '-110vw';
    setTimeout(() => {
      navigate(AppRoute.PORTFOLIO);
    }, 150);
  };

  const goNextProject = (e) => {
    if (idNumber >= 0 && idNumber < NUMBER_OF_PROJECTS) {
      navigate(`${AppRoute.PORTFOLIO}/${idNumber + 1}`);
    } else {
      shakeElement(e.currentTarget);
    }
  };

  const goPrevProject = (e) => {
    if (idNumber === 0) {
      shakeElement(e.currentTarget);
    } else {
      navigate(`${AppRoute.PORTFOLIO}/${idNumber - 1}`);
    }
  };

  useEffect(() => {
    setIsLoading({ type: ActionType.loadingOn });
    get(`/projects/${idNumber}`)
      .then((res) => setProject(res.data))
      .catch(() => {
        navigate(AppRoute.PORTFOLIO);
        toast(`No project with this ID#: ${id}`);
      })
      .finally(() => {
        setIsLoading({ type: ActionType.loadingOff });
      });
  }, [idNumber]);

  return (
    <div
      ref={refNode}
      className="fixed overflow-scroll sm:overflow-auto w-screen h-screen top-0 transition-[left] left-0 bg-white dark:bg-zinc-800 z-50"
    >
      <div className="max-w-screen-xl grid grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 mx-auto p-4 md:p-10">
        <h2 className="relative text-3xl font-bold lg:col-span-2 pl-10 pt-5">
          {project && project.title}
        </h2>

        <div className="justify-self-end flex">
          <button
            className="w-10 h-10 p-2 cursor-pointer rounded-sm bg-slate-100 hover:bg-slate-200 dark:bg-zinc-600 dark:hover:bg-zinc-400 mr-2 active:shadow-lg active:-translate-y-[2px]"
            onClick={(e) => {
              goPrevProject(e);
            }}
          >
            <svg
              className="dark:fill-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-40 78 352 352"
            >
              <path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
            </svg>
          </button>

          <button
            className="w-10 h-10 p-2 cursor-pointer rounded-sm bg-slate-100 hover:bg-slate-200 dark:bg-zinc-600 dark:hover:bg-zinc-400 mr-2 active:shadow-lg active:-translate-y-[2px]"
            onClick={(e) => {
              goNextProject(e);
            }}
          >
            <svg
              className="dark:fill-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-60 78 352 352"
            >
              <path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z" />
            </svg>
          </button>

          <button
            className="w-10 h-10 p-2 cursor-pointer rounded-sm bg-slate-100 hover:bg-slate-200 dark:bg-zinc-600 dark:hover:bg-zinc-400 active:shadow-lg active:-translate-y-[2px]"
            onClick={handleCloseClick}
          >
            <svg
              className="dark:fill-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 78 352 352"
            >
              <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
            </svg>
          </button>
        </div>

        <div className="rounded-lg col-span-2">
          {project && project.images && <Carousel images={project.images} />}
        </div>

        <div className="rounded-lg bg-slate-100 dark:bg-zinc-600 p-8 col-span-2 lg:col-span-1 shadow-md">
          <h2 className="text-lg font-bold">Description</h2>
          <div className="py-1">
            <svg
              className="w-4 inline-block"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill={pickedColor}
            >
              <path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z" />
            </svg>
            <span className="pl-5">Max Baravy</span>
          </div>
          {project && project.date && (
            <div className="py-1">
              <svg
                className="w-4 inline-block"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                fill={pickedColor}
              >
                <path d="M96 32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32zM448 464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192H448V464z" />
              </svg>
              <span className="pl-5">{project && project.date}</span>
            </div>
          )}
          <div className="py-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill={pickedColor}
              className="w-4 inline-block"
            >
              <path d="M352 256C352 278.2 350.8 299.6 348.7 320H163.3C161.2 299.6 159.1 278.2 159.1 256C159.1 233.8 161.2 212.4 163.3 192H348.7C350.8 212.4 352 233.8 352 256zM503.9 192C509.2 212.5 512 233.9 512 256C512 278.1 509.2 299.5 503.9 320H380.8C382.9 299.4 384 277.1 384 256C384 234 382.9 212.6 380.8 192H503.9zM493.4 160H376.7C366.7 96.14 346.9 42.62 321.4 8.442C399.8 29.09 463.4 85.94 493.4 160zM344.3 160H167.7C173.8 123.6 183.2 91.38 194.7 65.35C205.2 41.74 216.9 24.61 228.2 13.81C239.4 3.178 248.7 0 256 0C263.3 0 272.6 3.178 283.8 13.81C295.1 24.61 306.8 41.74 317.3 65.35C328.8 91.38 338.2 123.6 344.3 160H344.3zM18.61 160C48.59 85.94 112.2 29.09 190.6 8.442C165.1 42.62 145.3 96.14 135.3 160H18.61zM131.2 192C129.1 212.6 127.1 234 127.1 256C127.1 277.1 129.1 299.4 131.2 320H8.065C2.8 299.5 0 278.1 0 256C0 233.9 2.8 212.5 8.065 192H131.2zM194.7 446.6C183.2 420.6 173.8 388.4 167.7 352H344.3C338.2 388.4 328.8 420.6 317.3 446.6C306.8 470.3 295.1 487.4 283.8 498.2C272.6 508.8 263.3 512 255.1 512C248.7 512 239.4 508.8 228.2 498.2C216.9 487.4 205.2 470.3 194.7 446.6H194.7zM190.6 503.6C112.2 482.9 48.59 426.1 18.61 352H135.3C145.3 415.9 165.1 469.4 190.6 503.6V503.6zM321.4 503.6C346.9 469.4 366.7 415.9 376.7 352H493.4C463.4 426.1 399.8 482.9 321.4 503.6V503.6z" />
            </svg>
            <span className="pl-5">
              <a
                className="border-b-2 hover:border-zinc-600 animate-pulse"
                href={project && project.url}
              >
                Open site_
              </a>
            </span>
          </div>
          <div className="py-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill={pickedColor}
              className="w-4 inline-block"
            >
              <path d="M186.1 328.7c0 20.9-10.9 55.1-36.7 55.1s-36.7-34.2-36.7-55.1 10.9-55.1 36.7-55.1 36.7 34.2 36.7 55.1zM480 278.2c0 31.9-3.2 65.7-17.5 95-37.9 76.6-142.1 74.8-216.7 74.8-75.8 0-186.2 2.7-225.6-74.8-14.6-29-20.2-63.1-20.2-95 0-41.9 13.9-81.5 41.5-113.6-5.2-15.8-7.7-32.4-7.7-48.8 0-21.5 4.9-32.3 14.6-51.8 45.3 0 74.3 9 108.8 36 29-6.9 58.8-10 88.7-10 27 0 54.2 2.9 80.4 9.2 34-26.7 63-35.2 107.8-35.2 9.8 19.5 14.6 30.3 14.6 51.8 0 16.4-2.6 32.7-7.7 48.2 27.5 32.4 39 72.3 39 114.2zm-64.3 50.5c0-43.9-26.7-82.6-73.5-82.6-18.9 0-37 3.4-56 6-14.9 2.3-29.8 3.2-45.1 3.2-15.2 0-30.1-.9-45.1-3.2-18.7-2.6-37-6-56-6-46.8 0-73.5 38.7-73.5 82.6 0 87.8 80.4 101.3 150.4 101.3h48.2c70.3 0 150.6-13.4 150.6-101.3zm-82.6-55.1c-25.8 0-36.7 34.2-36.7 55.1s10.9 55.1 36.7 55.1 36.7-34.2 36.7-55.1-10.9-55.1-36.7-55.1z" />
            </svg>
            <span className="pl-5">
              <a className="border-b-2 hover:border-zinc-600" href={project && project.github}>
                GitHub code
              </a>
            </span>
          </div>
          <h2 className="mt-5 text-lg font-bold">Technology</h2>
          <p className="p-1">{project && project.stack}</p>
          <p className="py-3 italic">{project && project.description}</p>
          <h2 className="mt-5 text-lg font-bold pb-2">Share</h2>
          <FacebookShareButton url={'http://maxbaravy.com/'} className="mr-1">
            <FacebookIcon size={35} round />
          </FacebookShareButton>
          <LinkedinShareButton url={'http://maxbaravy.com/'} className="mr-1">
            <LinkedinIcon size={35} round />
          </LinkedinShareButton>
          <TwitterShareButton url={'http://maxbaravy.com/'}>
            <TwitterIcon size={35} round />
          </TwitterShareButton>
        </div>
      </div>
    </div>
  );
};

ProjectPage.propTypes = {
  projectsLength: PropTypes.number
};

export default ProjectPage;
