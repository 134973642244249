import SectionHeader from '../SectionHeader/SectionHeader';
import { filterColor } from '../../utils/filterColor';
import { useContext } from 'react';
import { ColorContext } from '../../context/Color';

const FunFacts = () => {
  const [state] = useContext(ColorContext);
  return (
    <section className="mb-20">
      <SectionHeader>Fun Facts</SectionHeader>
      <ul className="grid grid-cols-1 sm:grid-cols-3 gap-3 content-between">
        <li className="border-[1px] border-zinc-300 dark:border-black rounded-xl px-10 py-10 text-center opacity-80 dark:text-zinc-50 hover:shadow-xl hover:-translate-y-1 transition-all">
          <img
            src="/img/facts/users.svg"
            alt=""
            className={`w-10 mx-auto mb-5 ${filterColor(state.pickedColor)}`}
          />
          <p className="text-xl  font-semibold mb-5">Happy Clients</p>
          <p className="text-4xl text-zinc-400">100%</p>
        </li>
        <li className="border-[1px] border-zinc-300 dark:border-black rounded-xl px-10 py-10 text-center opacity-80 dark:text-zinc-50 hover:shadow-xl hover:-translate-y-1 transition-all">
          <img
            src="/img/facts/clock.svg"
            alt=""
            className={`w-10 mx-auto mb-5 ${filterColor(state.pickedColor)}`}
          />
          <p className="text-xl  font-semibold mb-5">Working Hours</p>
          <p className="text-4xl text-zinc-400">2,125</p>
        </li>
        <li className="border-[1px] border-zinc-300 dark:border-black rounded-xl px-10 py-10 text-center opacity-80 dark:text-zinc-50 hover:shadow-xl hover:-translate-y-1 transition-all">
          <img
            src="/img/facts/coffee.svg"
            alt=""
            className={`w-10 mx-auto mb-5 ${filterColor(state.pickedColor)}`}
          />
          <p className="text-xl font-semibold mb-5">Coffee Consumed</p>
          <p className="text-4xl text-zinc-400">1,534</p>
        </li>
      </ul>
    </section>
  );
};

export default FunFacts;
