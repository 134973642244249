import PropTypes from 'prop-types';
import { ColorContext } from '../../context/Color';
import { useEffect, useRef, useContext } from 'react';

const Header = ({ children, page = false }) => {
  const [state] = useContext(ColorContext);
  const ref = useRef(null);

  useEffect(() => {
    ref.current.style.setProperty('--color', state.pickedColor);
    // change bg-color on :after
  }, [state.pickedColor]);

  return (
    <div
      className={`${
        page
          ? '-mx-10 lg:-mx-20 bg-[rgba(1,1,1,0.03)] dark:bg-[rgba(255,255,255,0.03)] px-10 py-10 lg:px-20 mb-10'
          : ''
      } `}
    >
      <h2
        ref={ref}
        className={`${
          page
            ? 'text-5xl font-bold pb-2 after:h-[3px] before:h-[3px]'
            : 'text-2xl dark:text-zinc-50  mb-12 before:h-[2px] after:h-[2px]'
        } section-header relative inline-block
      before:block before:absolute before:w-full  before:bg-slate-300 before:bottom-0 
      after:block after:absolute after:w-[30px] after:bottom-0`}
      >
        {children}
      </h2>
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.bool
};

export default Header;
