import { useContext } from 'react';
import { ColorContext } from '../../context/Color';
import { LoadingContext } from '../../context/Loading';
import { filterColor } from '../../utils/filterColor';

const Preloader = () => {
  const [{ pickedColor }] = useContext(ColorContext);
  const [{ isLoading }] = useContext(LoadingContext);

  return (
    isLoading && (
      <div className="z-[100] fixed w-screen h-screen top-0 left-0 bg-[rgba(255,255,255,0.3)] flex ">
        <div className={`${filterColor(pickedColor)} w-12 mx-auto self-center`}>
          <img src="/img/spinner.svg" alt="Spinner" className="animate-spin" />
        </div>
      </div>
    )
  );
};

export default Preloader;
