import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const MenuItem = ({ item, onClick }) => {
  return (
    <li className="px-5 py-5 text-3xl md:text-base">
      <Link
        to={`/${Object.keys(item)}`}
        onClick={onClick}
        className="text-zinc-500 hover:text-zinc-900 dark:text-zinc-50 hover:dark:text-zinc-500"
      >
        {Object.values(item)}
      </Link>
    </li>
  );
};

MenuItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func
};

export default MenuItem;
