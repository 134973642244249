import PropTypes from 'prop-types';

const Toggle = ({ onToggleClick, darkMode }) => {
  return (
    <div
      tabIndex={0}
      className="z-0 absolute left-12 top-[76px] cursor-pointer transition-all w-[54px] h-[32px] rounded-[24px] bg-slate-400 text-[14px]"
      style={{ lineHeight: '32px' }}
      role="img"
      aria-label="light mode"
      onClick={() => {
        onToggleClick();
      }}
      onKeyPress={(evt) => {
        evt.preventDefault();
        if (evt.key === `Enter` || ` `) {
          onToggleClick();
        }
      }}
    >
      <span role="img" aria-label="light mode" className="absolute top-0 left-[3px]">
        off
      </span>
      <span role="img" aria-label="dark mode" className="absolute top-0 left-[30px]">
        on
      </span>
      <div
        className={`absolute top-[3.5px] ${
          !darkMode ? 'left-[3px]' : 'left-[26px]'
        } w-[25px] h-[25px] bg-white rounded-full transition-all`}
        tabIndex="0"
      ></div>
    </div>
  );
};

Toggle.propTypes = {
  onToggleClick: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired
};

export default Toggle;
