import { useEffect, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import ReactPlaceholder from 'react-placeholder/lib';
import 'react-placeholder/lib/reactPlaceholder.css';

import Project from '../../components/Project/Project';
import Button from '../../components/Button/Button';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import ProjectFilterList from '../../components/ProjectFilterList/ProjectFilterList';
import useFetch from '../../hooks/useFetch';
import { ProjectsContext } from '../../context/Projects';
import { LoadingContext } from '../../context/Loading';
import { ActionType } from '../../actions';

const Portfolio = () => {
  const { get } = useFetch();
  const [{ projects }, setProjects] = useContext(ProjectsContext);
  const [, setIsLoading] = useContext(LoadingContext);

  useEffect(() => {
    setIsLoading({ type: ActionType.loadingOn });
    get('/projects')
      .then((res) => {
        setProjects({ type: ActionType.setProjects, payload: res.data });
      })
      .finally(() => {
        setIsLoading({ type: ActionType.loadingOff });
      });
  }, []);

  return (
    <>
      <Outlet />
      <SectionHeader page>Portfolio</SectionHeader>
      <ProjectFilterList />
      <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {projects
          ? projects.map((project) => <Project {...project} key={project.id} />)
          : new Array(6).fill('').map((item, index) => {
              return (
                <ReactPlaceholder
                  key={item + index}
                  showLoadingAnimation
                  type="media"
                  rows={6}
                  className="p-5"
                  ready={projects}
                />
              );
            })}
      </ul>
      <Button colorfull classes="p-5 px-10 mt-10 block mx-auto">
        Load more
      </Button>
    </>
  );
};

export default Portfolio;
