import PropTypes from 'prop-types';
import { useEffect } from 'react';

const CertificateCard = ({ onClick, date, title, image, company }) => {
  useEffect(() => {}, []);

  return (
    <li
      onKeyPress={onClick}
      onClick={onClick}
      tabIndex={0}
      className="border-[1px] rounded-xl hover:-translate-y-2 hover:shadow-xl transition-all flex cursor-pointer overflow-hidden"
    >
      <div className="self-center">
        <img src={image} alt={title} className="w-[110px] h-auto pl-2 dark:filter-white" />
      </div>
      <div className="p-5">
        <h3 className="font-bold mb-2">{title}</h3>
        <p className="pl-3 opacity-70">{company}</p>
        <p className="pl-3 opacity-70 text-sm">{date}</p>
      </div>
    </li>
  );
};

CertificateCard.propTypes = {
  onClick: PropTypes.func,
  date: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  company: PropTypes.string
};

export default CertificateCard;
