import { useContext, useState } from 'react';
import CertificateCard from '../../components/CertificateCard/CertificateCard';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import TimeLineItem from '../../components/TimeLineItem/TimeLineItem';
import Viewer from '../../components/Viewer/Viewer';
import { ColorContext } from '../../context/Color';
import { CERTIFICATES, EDUCATION, EXPERIENCE } from '../../data';

const Resume = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [{ pickedColor }] = useContext(ColorContext);

  const openImageViewer = (index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <SectionHeader page>Resume</SectionHeader>
      <div className="grid md:grid-cols-2 gap-5">
        <div>
          <SectionHeader>Education</SectionHeader>
          <ul>
            {EDUCATION.map((item, index) => {
              return <TimeLineItem key={index} item={item} pickedColor={pickedColor} />;
            })}
          </ul>
        </div>
        <div>
          <SectionHeader>Experience</SectionHeader>
          <ul>
            {EXPERIENCE.map((item, index) => {
              return <TimeLineItem key={index} item={item} pickedColor={pickedColor} />;
            })}
          </ul>
        </div>
      </div>
      <div className="mt-10">
        <SectionHeader>Certificates</SectionHeader>
      </div>
      <ul className="grid md:grid-cols-2 gap-5">
        {isViewerOpen && (
          <Viewer
            currentImage={currentImage}
            images={CERTIFICATES.map((certificate) => certificate.imageViewer)}
            closeImageViewer={closeImageViewer}
          />
        )}
        {CERTIFICATES.map((item, index) => {
          return (
            <CertificateCard
              key={index}
              {...item}
              onClick={() => {
                openImageViewer(index);
              }}
            />
          );
        })}
      </ul>
    </>
  );
};

export default Resume;
