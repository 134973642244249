import SectionHeader from '../../components/SectionHeader/SectionHeader';
const Blog = () => {
  return (
    <>
      <SectionHeader page>Blog</SectionHeader>
      <p>{'Will be here shortly ;)'}</p>
    </>
  );
};

export default Blog;
