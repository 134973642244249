const LINKS = [
  { title: 'GitHub', url: 'https://github.com/maximkatut' },
  { title: 'Facebook', url: 'https://www.facebook.com/maxbaravy' },
  { title: 'Instagram', url: 'https://www.instagram.com/maximkatut' }
];

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="bg-slate-100 dark:bg-zinc-700 p-10 py-7 rounded-b-3xl flex flex-wrap justify-between text-zinc-500 border-t-2 dark:border-zinc-700 shadow-inner">
      <ul className="flex mx-auto md:mx-0">
        {LINKS.map((link) => (
          <li key={link.title} className="mx-5">
            <a
              href={link.url}
              className="text-sm hover:text-zinc-900 dark:text-zinc-50 hover:dark:text-zinc-500"
            >
              {link.title}
            </a>
          </li>
        ))}
      </ul>
      <div className="mx-auto md:mx-0 dark:text-zinc-200 text-zinc-500">
        © {year} All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
