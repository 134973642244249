import { useContext, useEffect, useRef, useState } from 'react';
import { ActionType } from '../../actions';
import { ColorContext } from '../../context/Color';
import Toggle from '../Toggle/Toggle';

const ColorTheme = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useContext(ColorContext);
  const ref = useRef(null);

  const toggleDarkClass = (darkMode) => {
    const htmlClassList = document.querySelector('html').classList;
    darkMode ? htmlClassList.add('dark') : htmlClassList.remove('dark');
  };

  const handleToggleClick = () => {
    dispatch({ type: ActionType.toggleDarkMode });
    toggleDarkClass(!state.darkMode);
  };

  useEffect(() => {
    toggleDarkClass(state.darkMode);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div ref={ref}>
      <button
        aria-label="open or close theme pallete"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={`flex items-center justify-center fixed w-9 h-9 top-40 z-50 ${
          isOpen ? 'right-28' : 'right-0'
        } border-zinc-500 
        bg-zinc-200 dark:bg-zinc-600 rounded-l-lg border-r-0 z-50 cursor-pointer transition-all`}
      >
        <span className="scale-[.6]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill={state.pickedColor}
            className="w-full"
          >
            <title>Theme pallete</title>
            <path d="M167.02 309.34c-40.12 2.58-76.53 17.86-97.19 72.3-2.35 6.21-8 9.98-14.59 9.98-11.11 0-45.46-27.67-55.25-34.35C0 439.62 37.93 512 128 512c75.86 0 128-43.77 128-120.19 0-3.11-.65-6.08-.97-9.13l-88.01-73.34zM457.89 0c-15.16 0-29.37 6.71-40.21 16.45C213.27 199.05 192 203.34 192 257.09c0 13.7 3.25 26.76 8.73 38.7l63.82 53.18c7.21 1.8 14.64 3.03 22.39 3.03 62.11 0 98.11-45.47 211.16-256.46 7.38-14.35 13.9-29.85 13.9-45.99C512 20.64 486 0 457.89 0z" />
          </svg>
        </span>
      </button>
      <div
        className={`grid grid-cols-3 gap-1 fixed w-28 h-18 top-40 z-50 ${
          isOpen ? 'right-0' : '-right-28'
        } p-1 bg-zinc-200 dark:bg-zinc-600 transition-all`}
      >
        {state.colors.map((color) => {
          return (
            <button
              aria-label="change color"
              key={color}
              onClick={() => {
                dispatch({ type: ActionType.changeColor, payload: color });
              }}
              style={{ backgroundColor: color }}
              className={`w-8 h-8 cursor-pointer`}
            ></button>
          );
        })}
        <span className="text-xs dark:text-zinc-100">Dark mode</span>
        <Toggle onToggleClick={handleToggleClick} darkMode={state.darkMode} />
      </div>
    </div>
  );
};

export default ColorTheme;
