export const filterColor = (color) => {
  switch (color) {
    case 'rgb(245,158,11)':
      return 'filter-yellow';
    case 'rgb(6,182,212)':
      return 'filter-teal';
    case 'rgb(20,184,166)':
      return 'filter-green';
    case 'rgb(217,70,239)':
      return 'filter-pink';
    case 'rgb(244,63,94)':
      return 'filter-red';
    case 'rgb(50,123,277)':
      return 'filter-blue';
    default:
      break;
  }
};
