import { useEffect, useState } from 'react';

const Y_OFFSET = 100;
const SCROLL_OPTIONS = {
  top: 0,
  behavior: 'smooth'
};

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisability = () => {
    return window.scrollY > Y_OFFSET ? setIsVisible(true) : setIsVisible(false);
  };

  const scrollToTop = () => {
    window.scrollTo(SCROLL_OPTIONS);
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisability);
  }, []);

  return (
    <button
      onClick={scrollToTop}
      type="button"
      className={`z-50 w-9 h-9 fixed bottom-10 right-10 transition-opacity bg-slate-200 hover:bg-slate-400 focus:outline-none rounded-md focus:ring-2 focus:ring-offset-2 ${
        isVisible ? 'opacity-80' : 'opacity-0'
      }`}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="w-9 h-9">
        <path d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z" />
      </svg>
    </button>
  );
};

export default ScrollToTop;
