import axios from 'axios';
import { useEffect, useState } from 'react';

const useFetch = (url, options) => {
  const [isError, setIsError] = useState(null);
  const [response, setResponse] = useState(null);

  const api = axios.create({
    baseURL: `https://api.maxbaravy.com/api/`,
    ...options
  });

  useEffect(() => {
    if (url) {
      const fetchData = async () => {
        await api(url)
          .then((res) => {
            setResponse(res.data);
          })
          .catch((err) => setIsError(err));
      };
      fetchData();
    }
  }, []);

  return { isError, response, get: api.get, post: api.post };
};

export default useFetch;
