import SectionHeader from '../SectionHeader/SectionHeader';
import WhatIDoCard from '../WhatIDoCard/WhatIDoCard';

const CARDS = [
  {
    title: 'Single Page App',
    description:
      "A single-page application is an app that doesn't need to reload the page during its use and works within a browser.",
    imgSrc: '/img/do/sitemap.svg'
  },
  {
    title: 'Accessibility',
    description:
      'The Web should be accessible and enjoyed by all. I am determined to put that into practice.',
    imgSrc: '/img/do/access.svg'
  },
  {
    title: 'Bug free',
    description:
      'Keeping in mind that no software is free from bugs, I carefully develop applications and do lots of testing to prevent bug applications as much as I can.',
    imgSrc: '/img/do/bug.svg'
  },
  {
    title: 'Responsive web design',
    description: 'My layouts will work on any device, big or small.',
    imgSrc: '/img/do/respons.svg'
  }
];

const WhatIDoList = () => {
  return (
    <section className="mb-20">
      <SectionHeader>What I Do</SectionHeader>
      <ul className="grid gap-6 lg:gap-8 grid-cols-4">
        {CARDS.map((card) => {
          return <WhatIDoCard {...card} key={card.title} />;
        })}
      </ul>
    </section>
  );
};

export default WhatIDoList;
