import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Carousel = ({ images }) => {
  return (
    <Swiper
      className="rounded-lg shadow-md"
      modules={[Navigation, Pagination, A11y, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      loop={true}
      pagination={{
        clickable: true
      }}
    >
      {images.map((image, index) => {
        return (
          <SwiperSlide key={index}>
            <img className="scale-105" src={image} alt="" />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string)
};

export default Carousel;
